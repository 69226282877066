var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import 'rxjs/Rx';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
var SystemConfigService = /** @class */ (function (_super) {
    __extends(SystemConfigService, _super);
    function SystemConfigService(http, configs) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        /**
         * {
         * "id": "S5685495992418304-byod",
            "data": {
                "P2": "V2",
                "P3": "V3",
                "translations": {
                    "super": "man",
                    "hello": "world"
                },
                "templateId": "e6ac2da6-6c1a-4497-b037-b08b0fbd39a6",
                "settings": {
                    "batchOrder": true
                }
            },
            "type": null
         * }
         */
        _this.systemConfig = {};
        _this.systemBrandConfig = {};
        _this.systemCourtConfig = {};
        _this.aq = new AQuery(http, configs);
        return _this;
    }
    Object.defineProperty(SystemConfigService.prototype, "systemConfigIsEmpty", {
        get: function () {
            return (Object.keys(this.systemConfig).length === 0) || (!this.systemConfig);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "systemBrandConfigIsEmpty", {
        get: function () {
            return (Object.keys(this.systemBrandConfig).length === 0) || (!this.systemBrandConfig);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "systemCourtConfigIsEmpty", {
        get: function () {
            return (Object.keys(this.systemCourtConfig).length === 0) || (!this.systemCourtConfig);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "courtShowOrderQueueInCart", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['courtShowOrderQueueInCart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "isBatchOrder", {
        get: function () {
            var batchOrder = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['settings'] && this.systemConfig['data']['settings']['batchOrder']) || false;
            return batchOrder;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "menuLayout", {
        get: function () {
            var layout = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['layout']) || {};
            return layout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "enableCoupon", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enableCoupon']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showOrderQueue", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueue']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showOrderQueueInCart", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueueInCart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideLoginPageRegister", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideLoginPageRegister']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideMemberProfilePage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideMemberProfilePage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideLoginPagePhoneInput", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideLoginPagePhoneInput']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideCourtLoginPageEmailInput", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideCourtLoginPageEmailInput']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideCourtRegister", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideCourtRegister']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "checkCourtMemberInStart", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['checkCourtMemberInStart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideStampPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideStampPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideResetPassword", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideResetPassword']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showCurrency", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showCurrency']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "estTimeMap", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['estTimeMap']) || [];
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showOrderQueueInOrderStatus", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueueInOrderStatus']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showOrderStatusPageCallNo", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderStatusPageCallNo']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showByodSummaryPageBillNo", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showByodSummaryPageBillNo']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hidePriceItemGridCart", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hidePriceItemGridCart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "itemPricePrecision", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['itemPricePrecision'] && this.systemConfig['data']['itemPricePrecision'] > -1);
            if (res) {
                return this.systemConfig['data']['itemPricePrecision'];
            }
            else {
                return -1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "pickUpTimeInterval", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickUpTimeInterval']) || false;
            if (res) {
                return this.systemConfig['data']['pickUpTimeInterval'];
            }
            else {
                //default
                return 15;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "pickUpTimeCounter", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickUpTimeCounter']) || false;
            if (res) {
                return this.systemConfig['data']['pickUpTimeCounter'];
            }
            else {
                //default
                return 99;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "pickUpTimeRequired", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickUpTimeRequired']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hidePickUpTimeDefault", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hidePickUpTimeDefault']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "OnlyModeInBrandStoreListPage", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['OnlyModeInBrandStoreListPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showLoginInCartPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showLoginInCartPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showLoginInMultiCartPage", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['showLoginInMultiCartPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showCourtTNCInCart", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['showCourtTNCInCart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "enlargeStoreLocationInfoInSplashPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enlargeStoreLocationInfoInSplashPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showStorePhoneInfoInSplashPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showStorePhoneInfoInSplashPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showStoreLocationInfoInByodSummaryPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showStoreLocationInfoInByodSummaryPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "courtGuestInfoNameInput", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['courtGuestInfoNameInput']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "courtGuestInfoSpotInput", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['courtGuestInfoSpotInput']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "comboPageGroupExpand", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['comboPageGroupExpand']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "enableFAQ", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enableFAQ']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showLoginInItemGridPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showLoginInItemGridPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showMemberLoginModalInItemGridPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showMemberLoginModalInItemGridPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "acceptMarketingInPaymentPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['acceptMarketingInPaymentPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "disableOrderStatusPageFirebaseListening", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['disableOrderStatusPageFirebaseListening']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * get System Config
     * @param storeId :string|number
     * @param brandId :string|number
     * @param courtId :string|number
     * @param callback (config:any)=> ()
     */
    SystemConfigService.prototype.getSystemConfig = function (storeId, callback) {
        var _this = this;
        if (!storeId)
            return;
        if (this.systemConfigIsEmpty || this.configStoreId !== storeId) {
            this.privateSystemConfig(storeId).subscribe(function (config) {
                _this.configStoreId = storeId;
                console.log('getSystemConfig:', config);
                if (callback)
                    callback(config);
            }, function (err) {
                if (callback)
                    callback({});
            });
        }
        else if (callback) {
            callback(this.systemConfig);
        }
    };
    SystemConfigService.prototype.getSystemBrandConfig = function (brandId, callback) {
        var _this = this;
        if (!brandId)
            return;
        if (this.systemBrandConfigIsEmpty || this.configBrandId !== brandId) {
            this.privateSystemBrandConfig(brandId).subscribe(function (config) {
                _this.configBrandId = brandId;
                console.log('getSystemBrandConfig:', config);
                if (callback)
                    callback(config);
            }, function (err) {
                if (callback)
                    callback({});
            });
        }
        else if (callback) {
            callback(this.systemBrandConfig);
        }
    };
    SystemConfigService.prototype.getSystemCourtConfig = function (courtId, callback) {
        var _this = this;
        if (!courtId)
            return;
        if (this.systemCourtConfigIsEmpty || this.configCourtId !== courtId) {
            this.privateSystemCourtConfig(courtId).subscribe(function (config) {
                _this.configCourtId = courtId;
                console.log('getSystemCourtConfig:', config);
                if (callback)
                    callback(config);
            }, function (err) {
                if (callback)
                    callback({});
            });
        }
        else if (callback) {
            callback(this.systemCourtConfig);
        }
    };
    SystemConfigService.prototype.privateSystemConfig = function (storeId, type) {
        var _this = this;
        if (type === void 0) { type = 'byod'; }
        var url = '/api/v1/store/config.json?type=' + type + '&storeId=' + storeId;
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().map(function (jo) {
            var data = (jo && jo['data']) || {};
            _this.systemConfig = data;
            return data;
        });
    };
    SystemConfigService.prototype.privateSystemBrandConfig = function (brandId, type) {
        var _this = this;
        if (type === void 0) { type = 'byod'; }
        var url = '/api/v1/store/config.json?type=' + type + '&brandId=' + brandId;
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().map(function (jo) {
            var data = (jo && jo['data']) || {};
            _this.systemBrandConfig = data;
            return data;
        });
    };
    SystemConfigService.prototype.privateSystemCourtConfig = function (courtId, type) {
        var _this = this;
        if (type === void 0) { type = 'byod'; }
        var url = '/api/v1/store/config.json?type=' + type + '&courtId=' + courtId;
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().map(function (jo) {
            var data = (jo && jo['data']) || {};
            _this.systemCourtConfig = data;
            return data;
        });
    };
    return SystemConfigService;
}(BaseService));
export { SystemConfigService };
